import { Routes, Route } from "react-router-dom";
import Talia from "./talia";
import ScrollableContainer from "../../components/scrollable-container";

export default function Private() {
    return (
        <Routes>
            <Route path="talia" element={
                <ScrollableContainer>
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            flexDirection: "column",
                        }}>

                        <h1 style={{
                            marginTop: "0.8em",
                            marginBottom: "0.8em",
                            textAlign: "center",
                        }}>"If he wanted to he would"</h1>
                    </div>

                    <Talia />
                </ScrollableContainer>
            } />
            <Route path="*" element={
                <div>
                    <h1>This page is private</h1>
                </div>
            } />
        </Routes>

    );
}